import { useAppSelector } from "@store/store-helper";
import {
  fetchedMembersFlagsSelector,
  fetchingMembersFlagsSelector,
  filteredCompanyMembersSelector,
} from "@store/members/members-selector";
import { useCompanyMembers } from "@hooks/use-company-members";
import { MainMemberDetails } from "@pages/members/main-member-details";
import { selectedSdbCompanySelector } from "@store/sdb-company/sdb-company-selector";
import { MembersEmptyPage } from "@components/common/empty-page/members-empty-page";
import { useMemo } from "react";
import { searchSelector } from "@store/ui/ui-selector";
import { SearchbarEvents } from "@utils/track-event/track-event-list";
import { MemberHeaders } from "@components/table/members/members-table-utils";
import { MembersTable } from "@components/table/members/members-table";
import { CompanyMemberBulkActions } from "@src/pages/members/company-member-bulk-actions";
import { NotFoundPage } from "@pages/not-found-page";
import { currentUserSelector } from "@store/user/user-selector";
import { InviteMemberToCompany } from "@pages/members/invite-member-to-company";
import { useDebouncedSearchText } from "@hooks/use-search";

/** Listing all the members of a company in a table */
export function MembersPage(): JSX.Element {
  useCompanyMembers();
  const filteredCompanyMembers = useAppSelector(filteredCompanyMembersSelector);
  const { isFetchingCompanyMembers } = useAppSelector(
    fetchingMembersFlagsSelector
  );
  const { hasFetchedWorkspaceMembers } = useAppSelector(
    fetchedMembersFlagsSelector
  );
  const selectedSdbCompany = useAppSelector(selectedSdbCompanySelector);
  const { searchText } = useAppSelector(searchSelector);
  const currentUser = useAppSelector(currentUserSelector);
  /**
   * Decides whether to show the empty page or not.
   * The empty page component handles the two different cases:
   * - No members at all (only the current user is in the workspace)
   * - No members that match the search
   */
  const shouldShowEmptyPage = useMemo(() => {
    if (isFetchingCompanyMembers || !hasFetchedWorkspaceMembers) {
      return false;
    }

    // If there are no members, show the empty page
    if (filteredCompanyMembers.length === 0) {
      return true;
    }

    if (searchText) {
      // If there are no members that match the search, show the empty page
      // Here if the only member is the current user we don't show the empty page,
      // only if there are no members at all
      return !filteredCompanyMembers.length;
    }

    if (filteredCompanyMembers.length === 1) {
      // If the only member is the current user, show the empty page
      return filteredCompanyMembers[0].identity === currentUser?.identity;
    }

    return false;
  }, [
    filteredCompanyMembers,
    isFetchingCompanyMembers,
    hasFetchedWorkspaceMembers,
    currentUser,
    searchText,
  ]);

  /** Uses useDebounce hook to react to changes on the search input text */
  useDebouncedSearchText({
    searchText,
    logEvent: {
      name: SearchbarEvents.searchForWorkspaceMember,
    },
  });

  if (!selectedSdbCompany) {
    return <NotFoundPage />;
  }

  const buttonComponents = CompanyMemberBulkActions({
    members: filteredCompanyMembers,
    company: selectedSdbCompany,
  });

  if (shouldShowEmptyPage) {
    return (
      <MembersEmptyPage
        subtitle="All of your workspace members will be found here. You can add members to your workspace by inviting them."
        button={<InviteMemberToCompany buttonVariant="filled" />}
      />
    );
  }

  return (
    <>
      <MainMemberDetails />
      {selectedSdbCompany && (
        <MembersTable
          companyId={selectedSdbCompany.id}
          members={filteredCompanyMembers}
          tableSubject="workspace"
          isLoading={isFetchingCompanyMembers}
          requiredColumns={[
            MemberHeaders.avatar,
            MemberHeaders.user,
            MemberHeaders.email,
            MemberHeaders.status,
            MemberHeaders.role,
            MemberHeaders.options,
          ]}
          bulkActionButtons={buttonComponents}
        />
      )}
    </>
  );
}
